import { useMutation } from 'react-query'
import KEY from './keys'
import { usePublicApi } from '../../PublicApiContext'

type UpdateCurrentPageArgs = {
  workflowStateId: number
  currentPageNo: number
}

function useUpdateCurrentPage() {
  const api = usePublicApi()
  return useMutation({
    mutationKey: KEY.WORKFLOW_STATE,
    mutationFn: ({ workflowStateId, currentPageNo }: UpdateCurrentPageArgs) =>
      api.workflowStateController.updatePage(workflowStateId, currentPageNo).then((r) => r.data),
  })
}

export default useUpdateCurrentPage
