import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { runInAction } from 'mobx'
import { useMsal } from '@azure/msal-react'
import { Box, LinearProgress } from '@mui/material'
import SurveyProvider from './SurveyProvider'
import GA from './google-analytics'
import { mode } from './api/public'
import KEY from './api/hooks/workflow-state/keys'
import { CustomClaims } from './auth/msal'
import UserHeader from './components/UserHeader'
import Alerts from '../common/alerts/Alerts'
import SurveyContext, { surveyContext } from './context/SurveyContext'
import AlertsContext, { alertsContext } from '../common/alerts/AlertsContext'
import { usePublicApi } from './api/PublicApiContext'

function App() {
  const location = useLocation()
  const api = usePublicApi()
  if (location.pathname === '/signup') {
    mode.signup = true
  }
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { instance } = useMsal()
  const ctx = surveyContext

  // TODO move to the Workflow Controller and refactor it
  useQuery(KEY.WORKFLOW_STATE, async () => {
    const newSession = ctx.session === null
    if (newSession) {
      if (ctx.campaign === undefined) {
        runInAction(() => {
          ctx.campaign = searchParams.get('campaign') // null if no campaign
        })
      }
      const session = await api.campaignSessionController
        .create1({ campaign: ctx.campaign || undefined })
        .then((r) => r.data)

      runInAction(() => {
        ctx.session = session
      })
    }

    if (ctx.session) {
      const workflow = await api.campaignSessionController.startWorkflow(ctx.session.id).then((r) => r.data)
      runInAction(() => {
        ctx.workflow = workflow
        if (ctx.survey) {
          if (ctx.survey.completedHtml) {
            const firstName = instance.getActiveAccount()?.idTokenClaims?.[CustomClaims.given_name]?.toString() || ''
            if (firstName) {
              const p = document.createElement('p')
              p.innerText = firstName
              ctx.survey.completedHtml = ctx.survey.completedHtml.replace('#{user.firstName}', p.innerHTML)
              p.remove()
            }
          }
          ctx.survey.data = JSON.parse(workflow.answersJson || '{}')
          if (workflow.completedAt) {
            ctx.survey.doComplete(false)
            navigate('survey', { replace: true })
            return
          }
          ctx.survey.currentPageNo = workflow.currentPage || 0
          for (let i = 0; i <= ctx.survey.currentPageNo; i++) {
            if (i === 0) {
              navigate('survey', { replace: true })
            } else {
              navigate(`survey/${i}`)
            }
          }
        }
      })
    }
  })

  useEffect(() => {
    // initialize Google Analytics
    GA.gtag('event', 'page_view', {
      page_title: document?.title,
      page_location: window?.location.toString(),
      page_path: location.pathname,
    })
  }, [location])

  return (
    <AlertsContext.Provider value={alertsContext}>
      <SurveyContext.Provider value={surveyContext}>
        <UserHeader />
        {!surveyContext.survey && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Alerts />
        <Routes>
          <Route path="/survey/:page?" element={<SurveyProvider />} />
          <Route path="*" element={<Navigate to="/survey" />} />
        </Routes>
      </SurveyContext.Provider>
    </AlertsContext.Provider>
  )
}

export default App
