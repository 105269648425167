/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Attachment {
  /** @format int32 */
  id: number
  /** @format int32 */
  userId: number
  /** @format int32 */
  blobId: number
}

export interface SignUpFormDto {
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string
  email?: string
  /** @pattern ^\+1 \([1-9]\d{2}\) \d{3}-\d{4}$| */
  phone?: string
  answersJson: string
}

export interface CreatedCandidateDto {
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string
  email?: string
  /** @pattern ^\+1 \([1-9]\d{2}\) \d{3}-\d{4}$| */
  phone?: string
  /** @format int32 */
  id: number
}

export interface NewCampaignSessionDto {
  /** @format int32 */
  id: number
  /** @format date-time */
  createdAt: string
}

export interface WorkflowStateDto {
  /** @format int32 */
  workflowDefinitionId: number
  /** @format int32 */
  workflowStateId: number
  surveyJson: string
  answersJson?: string
  /** @format int32 */
  currentPage?: number
  /** @format date-time */
  completedAt?: string
}

export interface FeatureFlagDto {
  /** @format int32 */
  id: number
  /**
   * @minLength 0
   * @maxLength 250
   */
  name: string
}

export interface UploadFilePayload {
  /** @format binary */
  file: File
}

export interface CreateParams {
  originType: 'STANDARD_APP' | 'EVENT_APP' | 'CHIME' | 'REFERRAL' | 'MANUALLY'
}

export interface Create1Params {
  /** Create new campaign by its name, or create default campaign if name is not specified */
  campaign?: string
}

export type UpdateJsonPayload = string

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://localhost:8080'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://localhost:8080
 */
export class PublicApi<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  workflowStateController = {
    /**
     * No description
     *
     * @tags workflow-state-controller
     * @name CompleteStep
     * @request POST:/api/workflow-state/{workflowStateId}/complete
     * @secure
     */
    completeStep: (workflowStateId: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/workflow-state/${workflowStateId}/complete`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workflow-state-controller
     * @name UpdatePage
     * @request PATCH:/api/workflow-state/{workflowStateId}/page/{currentPage}
     * @secure
     */
    updatePage: (workflowStateId: number, currentPage: number, params: RequestParams = {}) =>
      this.http.request<boolean, any>({
        path: `/api/workflow-state/${workflowStateId}/page/${currentPage}`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workflow-state-controller
     * @name UpdateJson
     * @request PATCH:/api/workflow-state/{workflowStateId}/json
     * @secure
     */
    updateJson: (workflowStateId: number, data: UpdateJsonPayload, params: RequestParams = {}) =>
      this.http.request<boolean, any>({
        path: `/api/workflow-state/${workflowStateId}/json`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Text,
        format: 'json',
        ...params,
      }),
  }
  fileUploadController = {
    /**
     * No description
     *
     * @tags file-upload-controller
     * @name UploadFile
     * @summary Upload file to Azure Blob Storage and Save file meta information on DB.
     * @request POST:/api/files/{workflowStateId}
     * @secure
     */
    uploadFile: (workflowStateId: number, data: UploadFilePayload, params: RequestParams = {}) =>
      this.http.request<Attachment, Attachment>({
        path: `/api/files/${workflowStateId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags file-upload-controller
     * @name Download
     * @summary Download file.
     * @request GET:/api/files/{fileId}
     * @secure
     */
    download: (fileId: number, params: RequestParams = {}) =>
      this.http.request<File, File>({
        path: `/api/files/${fileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  }
  candidates = {
    /**
     * No description
     *
     * @tags Candidates
     * @name Create
     * @summary Create new candidate record
     * @request POST:/api/candidates
     */
    create: (query: CreateParams, data: SignUpFormDto, params: RequestParams = {}) =>
      this.http.request<CreatedCandidateDto, CreatedCandidateDto | void>({
        path: `/api/candidates`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  campaignSessionController = {
    /**
     * @description Creates new campaign, application should call it on the first render only
     *
     * @tags campaign-session-controller
     * @name Create1
     * @request POST:/api/campaign-session
     */
    create1: (query: Create1Params, params: RequestParams = {}) =>
      this.http.request<NewCampaignSessionDto, any>({
        path: `/api/campaign-session`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Authentication protected end-point to initialize campaign's workflow for the authenticated user
     *
     * @tags campaign-session-controller
     * @name StartWorkflow
     * @request POST:/api/campaign-session/{campaignSessionId}/workflow
     * @secure
     */
    startWorkflow: (campaignSessionId: number, params: RequestParams = {}) =>
      this.http.request<WorkflowStateDto, WorkflowStateDto>({
        path: `/api/campaign-session/${campaignSessionId}/workflow`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  featureFlags = {
    /**
     * @description Returns all active feature flags
     *
     * @tags Feature Flags
     * @name GetActive
     * @request GET:/api/feature-flags
     */
    getActive: (params: RequestParams = {}) =>
      this.http.request<FeatureFlagDto[], any>({
        path: `/api/feature-flags`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
}
