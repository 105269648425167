import { createTheme } from '@mui/material'
import CheckBoxOutlineBlankIcon from './icons/CheckBoxOutlineBlankIcon'
import RadioButtonIcon from './icons/RadioButtonIcon'
import muiTheme, { colors } from '../common/mui-theme'

const appTheme = createTheme({
  ...muiTheme,
  components: {
    ...muiTheme.components,
    MuiOutlinedInput: {
      ...muiTheme.components?.MuiOutlinedInput,
      styleOverrides: {
        ...muiTheme.components?.MuiOutlinedInput?.styleOverrides,
        root: {
          ...(muiTheme.components?.MuiOutlinedInput?.styleOverrides?.root as object),
          backgroundColor: colors.base.white,
        },
      },
    },
    MuiCheckbox: {
      ...muiTheme.components?.MuiCheckbox,
      defaultProps: {
        ...muiTheme.components?.MuiCheckbox?.defaultProps,
        icon: <CheckBoxOutlineBlankIcon />,
      },
    },
    MuiRadio: {
      ...muiTheme.components?.MuiRadio,
      defaultProps: {
        ...muiTheme.components?.MuiRadio?.defaultProps,
        icon: <RadioButtonIcon />,
      },
      styleOverrides: {
        ...muiTheme.components?.MuiRadio?.styleOverrides,
        root: {
          ...(muiTheme.components?.MuiRadio?.styleOverrides?.root as object),
          '.MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
            fontSize: '1.5rem',
          },
        },
      },
    },
  },
})

export default appTheme
