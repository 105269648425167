import BaseHeader from '../../common/components/BaseHeader'
import CrashAlert from '../../common/alerts/CrashAlert'

export default function ErrorPage() {
  return (
    <>
      <BaseHeader actions={undefined} progress={undefined} />
      <CrashAlert />
    </>
  )
}
