import React, { ReactNode } from 'react'
import { AppBar, SxProps, Theme, Toolbar } from '@mui/material'
import HealthCareerLab from '../icons/HealthCareerLab'
import styles from './BaseHeader.module.css'
import { colors, palette } from '../mui-theme'

function BaseHeader({ actions, progress, sx }: { actions?: ReactNode; progress?: ReactNode; sx?: SxProps<Theme> }) {
  return (
    <AppBar
      position="static"
      color="secondary"
      sx={{
        position: 'relative',
        backgroundColor: colors.base.white,
        color: palette.secondary.main,
        zIndex: 2,
        ...(sx || {}),
      }}
    >
      <Toolbar>
        <div className={styles.logo}>
          <HealthCareerLab />
        </div>
        <div style={{ flexGrow: 1 }} />
        {actions}
      </Toolbar>
      {progress}
    </AppBar>
  )
}

export default BaseHeader
