import React from 'react'
import { colors } from '../../common/mui-theme'

export default function RadioButtonIcon() {
  return (
    <svg
      className="MuiSvgIcon-root"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="RadioButtonUncheckedIcon"
    >
      <circle cx="12" cy="12" r="9" fill={colors.base.white} />
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        fill="currentColor"
      />
    </svg>
  )
}
