import { useContext, useEffect, useState } from 'react'
import styles from './SurveyDisabler.module.css'
import AlertsContext from '../../common/alerts/AlertsContext'

const userIsOffline = 'You are offline'

export default function SurveyDisabler() {
  const { removeAlerts, addAlert } = useContext(AlertsContext)
  const [offline, setOffline] = useState(!window.navigator.onLine)

  useEffect(() => {
    const onOnline = () => {
      setOffline(false)
      removeAlerts((alert) => alert.title === userIsOffline)
    }
    const onOffline = () => {
      setOffline(true)
      addAlert(
        'warning',
        userIsOffline,
        'Your progress has been saved. You may continue once your connection is restored.',
        'system',
        'dismiss',
      )
    }

    window.addEventListener('online', onOnline, false)
    window.addEventListener('offline', onOffline, false)
    return () => {
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [addAlert, removeAlerts])

  return offline ? <div className={styles.disabler} /> : null
}
