import css from './ProgressBar.module.css'

function invalidate(progress: number) {
  if (Number.isNaN(progress) || progress < 0) return 0
  return progress > 100 ? 100 : progress
}

function ProgressBar({ progress }: { progress: number }) {
  return (
    <div className={css.progress}>
      <div className={css.progressBar} style={{ width: `${invalidate(progress).toString()}%` }} />
    </div>
  )
}

export default ProgressBar
