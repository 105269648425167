import { useMutation } from 'react-query'
import KEY from './keys'
import { usePublicApi } from '../../PublicApiContext'

type SubmitAnswersArgs = {
  workflowStateId: number
  answersJson: string
}

export default function useUpdateAnswers() {
  const api = usePublicApi()
  return useMutation({
    mutationKey: KEY.WORKFLOW_STATE,
    mutationFn: ({ workflowStateId, answersJson }: SubmitAnswersArgs) =>
      api.workflowStateController.updateJson(workflowStateId, answersJson).then((r) => r.data),
  })
}
