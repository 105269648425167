import React, { useContext, useEffect } from 'react'
import { PageModel, Question, QuestionFileModel, SurveyModel } from 'survey-core'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import MuiSurvey, { MuiSurveyProps } from '../common/survey/MuiSurvey'
import SurveyContext from './context/SurveyContext'
import useUpdateCurrentPage from './api/hooks/workflow-state/useUpdateCurrentPage'
import useUpdateAnswers from './api/hooks/workflow-state/useUpdateAnswers'
import useCompleteSurvey from './api/hooks/workflow-state/useCompleteSurvey'
import { createOnDownloadFileHandler, createOnUploadFilesHandler } from '../common/survey/MuiFileQuestion'
import WorkExperienceModel from '../common/survey/work-experience/WorkExperienceModel'
import SurveyDisabler from './components/SurveyDisabler'
import { usePublicApi } from './api/PublicApiContext'
import { FileQuestionValue } from '../common/survey/file-question-value'
import { Attachment } from './_generated/public-api'

const RoutedSurvey = observer(({ model }: MuiSurveyProps) => {
  const navigate = useNavigate()
  const { page = '0' } = useParams()
  const { workflow } = useContext(SurveyContext)
  const updatePage = useUpdateCurrentPage()
  const updateAnswers = useUpdateAnswers()
  const completeSurvey = useCompleteSurvey()
  const api = usePublicApi()
  // navigate to the page by url
  useEffect(() => {
    // TODO validate previous pages are answered
    let pageNo = parseInt(page, 10)
    if (Number.isNaN(pageNo)) pageNo = 0
    if (model.currentPageNo !== pageNo) {
      // eslint-disable-next-line no-param-reassign
      model.currentPageNo = pageNo
      if (workflow) {
        runInAction(() => {
          workflow.currentPage = pageNo
        })
        updatePage.mutate({ workflowStateId: workflow.workflowStateId, currentPageNo: pageNo })
      }
    }
  }, [model, page, updatePage, workflow])

  // update url on page changed event
  useEffect(() => {
    const onCurrentPageChanged = (
      sender: SurveyModel,
      options: {
        oldCurrentPage: PageModel
        newCurrentPage: PageModel
        isNextPage: boolean
        isPrevPage: boolean
      },
    ) => {
      if (!workflow) return

      runInAction(() => {
        workflow.currentPage = options.newCurrentPage.num - 1
      })
      updatePage.mutate({ workflowStateId: workflow.workflowStateId, currentPageNo: workflow.currentPage || 0 })

      const dataJson = JSON.stringify(sender.data)
      if (workflow.answersJson !== dataJson) {
        runInAction(() => {
          workflow.answersJson = dataJson
        })
        updateAnswers.mutate({ workflowStateId: workflow.workflowStateId, answersJson: dataJson })
      }

      if (options.isPrevPage) {
        navigate(-1)
      } else {
        const path = `/survey/${options.newCurrentPage.num - 1}`
        navigate(path)
      }
    }
    model.onCurrentPageChanged.add(onCurrentPageChanged)
    return () => model.onCurrentPageChanged.remove(onCurrentPageChanged)
  }, [navigate, model, workflow, updatePage, updateAnswers])

  useEffect(() => {
    const onComplete = (sender: SurveyModel) => {
      if (workflow) {
        const dataJson = JSON.stringify(sender.data)
        if (workflow.answersJson !== dataJson) {
          runInAction(() => {
            workflow.answersJson = dataJson
          })
          updateAnswers.mutate({ workflowStateId: workflow.workflowStateId, answersJson: dataJson })
        }
        completeSurvey.mutate(workflow.workflowStateId)
      }
    }
    model.onComplete.add(onComplete)
    return () => model.onComplete.remove(onComplete)
  }, [completeSurvey, model, updateAnswers, workflow])

  // handle deletion of files
  useEffect(() => {
    const onClearFiles = (
      sender: SurveyModel,
      {
        question,
        value,
        fileName,
        callback,
      }: {
        question: QuestionFileModel
        value: FileQuestionValue[]
        fileName: string
        callback: (status: string) => void
      },
    ) => {
      if (!workflow) return
      const index = question.removalIndex as number | undefined
      if (index !== undefined && value[index]?.name === fileName) {
        // eslint-disable-next-line no-param-reassign
        question.removalIndex = undefined
        callback('success')
      }
    }
    model.onClearFiles.add(onClearFiles)
    return () => model.onClearFiles.remove(onClearFiles)
  }, [api.fileUploadController, model.onClearFiles, workflow])

  // handle upload
  useEffect(() => {
    if (!workflow) return undefined

    const onUploadFiles = createOnUploadFilesHandler((file) =>
      api.fileUploadController
        .uploadFile(workflow.workflowStateId, { file })
        .then((response) => response.json())
        .then((attachment: Attachment) => ({ file, content: { id: attachment.id, size: file.size } })),
    )

    model.onUploadFiles.add(onUploadFiles)
    return () => model.onUploadFiles.remove(onUploadFiles)
  }, [api.fileUploadController, model.onUploadFiles, workflow])

  useEffect(() => {
    const onDownloadFile = createOnDownloadFileHandler((fileId) =>
      api.fileUploadController.download(fileId).then((response) => response.blob()),
    )
    model.onDownloadFile.add(onDownloadFile)
    return () => model.onDownloadFile.remove(onDownloadFile)
  }, [api.fileUploadController, model])

  useEffect(() => {
    const storeAnswers = (sender: SurveyModel) => {
      if (!workflow) return
      const answersJson = JSON.stringify(sender.data)
      runInAction(() => {
        workflow.answersJson = answersJson
      })
      updateAnswers.mutate({ workflowStateId: workflow.workflowStateId, answersJson })
    }
    const onValueChanged = (sender: SurveyModel, { question }: { question: Question }) => {
      if (question.getType() === 'file' || question.getType() === WorkExperienceModel.TYPE) {
        storeAnswers(sender)
      }
    }
    model.onValueChanged.add(onValueChanged)
    return () => model.onValueChanged.remove(onValueChanged)
  }, [model, updateAnswers, workflow])

  return (
    <div>
      <SurveyDisabler />
      <MuiSurvey model={model} />
    </div>
  )
})

function SurveyProvider() {
  const { survey } = useContext(SurveyContext)
  return survey ? <RoutedSurvey model={survey} /> : null
}

export default observer(SurveyProvider)
