import { useMutation } from 'react-query'
import KEY from './keys'
import { usePublicApi } from '../../PublicApiContext'

export default function useCompleteSurvey() {
  const api = usePublicApi()
  return useMutation({
    mutationKey: KEY.WORKFLOW_STATE,
    mutationFn: (workflowStateId: number) =>
      api.workflowStateController.completeStep(workflowStateId).then((r) => r.data),
  })
}
