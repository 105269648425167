import ReactGA from 'react-ga4'

const TRACKING_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID

ReactGA.initialize(TRACKING_ID, {
  testMode: false, // TODO disable later after testing import.meta.env.DEV,
})
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages#manual_pageviews
ReactGA.gtag('config', TRACKING_ID, { send_page_view: false })

const GA = ReactGA

export default GA
