import React, { useEffect, useState } from 'react'
import { SurveyModel } from 'survey-core'
import ProgressBar from './ProgressBar'

export default function SurveyProgressBar({ survey }: { survey: SurveyModel }) {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    const updateProgress = () => {
      switch (survey.state) {
        case 'completed': {
          setProgress(100)
          break
        }

        case 'running': {
          const completed = survey.pages.indexOf(survey.currentPage)
          const total = survey.pageCount
          setProgress(total === 0 ? 0 : (completed * 100.0) / total)
          break
        }

        default: {
          setProgress(0)
          break
        }
      }
    }
    updateProgress()
    survey.onStarted.add(updateProgress)
    survey.onCurrentPageChanged.add(updateProgress)
    survey.onComplete.add(updateProgress)
    return () => {
      survey.onStarted.remove(updateProgress)
      survey.onCurrentPageChanged.remove(updateProgress)
      survey.onComplete.remove(updateProgress)
    }
  }, [survey])

  return <ProgressBar progress={progress} />
}
