import { createContext } from 'react'
import { action, makeAutoObservable, reaction, toJS } from 'mobx'
import { sessionStorage } from 'reactive-localstorage'
import { NewCampaignSessionDto, WorkflowStateDto } from '../_generated/public-api'
import { welcomeSurvey } from '../../demo/survey-model'

const KEY = {
  CAMPAIGN: 'SurveyContext.campaign',
  SESSION: 'SurveyContext.session',
} as const

class SurveyContextValue {
  campaign?: string | null = undefined

  session: NewCampaignSessionDto | null = null

  workflow: WorkflowStateDto | null = null

  constructor() {
    const keys = new Set()
    for (let i = 0; i < sessionStorage.length; i++) keys.add(sessionStorage.key(i))
    if (keys.has(KEY.CAMPAIGN)) {
      this.campaign = JSON.parse(sessionStorage.getItem(KEY.CAMPAIGN) || 'null')
    }
    if (keys.has(KEY.SESSION)) {
      this.session = JSON.parse(sessionStorage.getItem(KEY.SESSION) || 'null')
    }

    makeAutoObservable(this, undefined, { autoBind: true })
    const onChange = action((key: string, value: string) => {
      switch (key) {
        case KEY.CAMPAIGN: {
          const campaignJSON = JSON.stringify(this.campaign)
          if (campaignJSON !== value) {
            this.campaign = JSON.parse(value)
          }
          break
        }
        case KEY.SESSION: {
          const sessionJSON = JSON.stringify(toJS(this.session))
          if (sessionJSON !== value) {
            this.session = JSON.parse(value)
          }
          break
        }
        default:
          break
      }
    })
    sessionStorage.on('change', onChange)
    reaction(
      () => this.campaign,
      (campaign) => sessionStorage.setItem(KEY.CAMPAIGN, JSON.stringify(campaign)),
    )
    reaction(
      () => this.session,
      (session) => sessionStorage.setItem(KEY.SESSION, JSON.stringify(toJS(session))),
    )
  }

  get survey() {
    if (!this.workflow?.surveyJson) return null
    // welcomeSurvey.data = JSON.parse(this.workflow.answersJson || 'null')
    // welcomeSurvey.currentPageNo = this.workflow.currentPage || 0
    return welcomeSurvey // TODO (JSON.parse(this.workflow.surveyJson) as SurveyModel)
  }

  cleanUp = () => {
    this.workflow = null
    this.session = null
  }
}

export const surveyContext = new SurveyContextValue()

const SurveyContext = createContext(surveyContext)

export default SurveyContext
