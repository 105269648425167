import { PublicClientApplication, RedirectRequest } from '@azure/msal-browser'
import { HttpClient, PublicApi } from '../_generated/public-api'
import msal from '../auth/msal'
import appInsight, { SeverityLevel } from '../../common/app-insights'
import customFetch from '../../common/fetch/custom-fetch'

export const mode = {
  signup: false,
}

const MSAL = { Component: 'MSAL' }
const client = new HttpClient<PublicClientApplication>({
  baseUrl: import.meta.env.VITE_PUBLIC_API,
  securityWorker: async (auth) => {
    if (!auth) return {}
    const scopes = [import.meta.env.VITE_B2C_SCOPE_URI, 'openid']
    const loginRequest: RedirectRequest = { scopes } // , extraQueryParameters: { p: 'B2C_1_su' }
    if (mode.signup) {
      loginRequest.extraQueryParameters = { signup: 'true' }
    }
    try {
      await auth.handleRedirectPromise()
    } catch (e) {
      appInsight.trackException({ exception: e as Error, severityLevel: SeverityLevel.Warning }, MSAL)
      await auth.loginRedirect(loginRequest)
    }
    const accounts = auth.getAllAccounts()
    const account = accounts.find((a) => auth.getConfiguration().auth.clientId === a.idTokenClaims?.aud) || null
    if (!account) {
      await auth.loginRedirect(loginRequest)
      return {}
    }
    const result = await auth
      .acquireTokenSilent({ account: account || undefined, scopes })
      .then((res) => {
        auth.setActiveAccount(res.account)
        return res
      })
      .catch(async (e) => {
        appInsight.trackTrace({ message: e, severityLevel: SeverityLevel.Error }, MSAL)
        await auth.loginRedirect({ scopes })
        return { accessToken: null }
      })
    return result.accessToken ? { headers: { Authorization: `Bearer ${result.accessToken}` } } : {}
  },
  customFetch,
})

client.setSecurityData(msal)

const publicApi = new PublicApi(client)

export default publicApi
