import { useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { observer } from 'mobx-react'
import SurveyContext from '../context/SurveyContext'
import AccountActions from './header/AccountActions'
import SurveyProgressBar from './SurveyProgressBar'
import BaseHeader from '../../common/components/BaseHeader'

const UserHeader = observer(() => {
  const { survey } = useContext(SurveyContext)
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const actions = import.meta.env.VITE_HIDE_LOGOUT !== 'true' && account && account.idTokenClaims && (
    <AccountActions account={account} />
  )
  const progress = survey && <SurveyProgressBar survey={survey} />
  return <BaseHeader actions={actions} progress={progress} />
})

export default UserHeader
