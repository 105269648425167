import { createContext, useContext } from 'react'
import publicApi from './public'

const PublicApiContext = createContext(publicApi)

export function usePublicApi() {
  return useContext(PublicApiContext)
}

export default PublicApiContext
