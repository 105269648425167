import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import '../common/global.css'
import './public-app.css'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MsalProvider } from '@azure/msal-react'
import App from './App'
import appInsight from '../common/app-insights'
import msal from './auth/msal'
import ErrorPage from './components/ErrorPage'
import PublicApiContext from './api/PublicApiContext'
import publicApi from './api/public'
import appTheme from './app-theme'

const container = document.getElementById('root') || document.body

const root = createRoot(container)
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

root.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={appTheme}>
        <AppInsightsContext.Provider value={appInsight}>
          <AppInsightsErrorBoundary appInsights={appInsight} onError={ErrorPage}>
            <MsalProvider instance={msal}>
              <QueryClientProvider client={queryClient}>
                <PublicApiContext.Provider value={publicApi}>
                  <App />
                </PublicApiContext.Provider>
              </QueryClientProvider>
            </MsalProvider>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
)
