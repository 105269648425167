import React, { CSSProperties } from 'react'
import { colors } from '../../common/mui-theme'

export default function CheckBoxOutlineBlankIcon({ style }: { style?: CSSProperties }) {
  return (
    <svg
      className="MuiSvgIcon-root"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="CheckBoxOutlineBlankIcon"
      style={style}
    >
      <rect x="4" y="4" width="16" height="16" fill={colors.base.white} />
      <path
        d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
        fill="currentColor"
      />
    </svg>
  )
}
